.diagram {
  position: relative;
  z-index: 0;
}

.diagram_item {
  position: absolute;
  left: 0;
  top: 0;
  width: max-content;
  height: max-content;

  &.diagram_item-graphic {
    pointer-events: none;
  }
}

.diagram_onomatopoeic, .diagram_uncertain {
  font-style: italic;
  display: block;
  margin-bottom: 1em;
}