.errorPage {
  background-color: var(--color-danger);
  color: var(--color-text-inverse);
  min-height: 100%;
  box-sizing: border-box;
  padding: 24px;
  display: grid;
  grid-template-areas: ". .       ."
                       ". heading ."
                       ". text    ."
                       ". link    ."
                       ". .       .";
  grid-template-rows: 1fr auto auto auto 2fr;
  grid-template-columns: 1fr auto 2fr;
}

.errorPage_heading {
  font-size: 40px;
  grid-area: heading;
  margin: 0;
}

.errorPage_text {
  font-size: 20px;
  grid-area: text;
  margin: 16px 0 0 0;
}

.errorPage_linkContainer {
  font-size: 20px;
  grid-area: link;
  margin: 32px 0 0 0;
}

.errorPage_link {
  color: var(--color-text-inverse);
  transition: color var(--duration-color-transition);
}

.errorPage_link:hover {
  color: var(--color-text-inverse-highlighted);
}