.collapsable-collapsed {
  animation: collapsable-collapse var(--duration-transform-transition);
  height: 0; // So that resizing window doesn't create problems
  overflow: hidden;
  visibility: hidden; // So that it's not tabbable
}

@keyframes collapsable-collapse {
  from {
    margin-top: 0;
    height: var(--collapsable-height);
    overflow: visible;
    visibility: visible;
  }

  to {
    margin-top: calc(-1 * var(--collapsable-height));
    height: var(--collapsable-height);
    overflow: visible;
    visibility: visible;
  }
}