.loading_preloader {
  --loading-animation-parameters: 2s linear infinite;

  width: 128px;
  animation: loadingIcon_opacity var(--loading-animation-parameters);

  .loadingIcon_nodes {
    fill: var(--color-text);
  }

  .loadingIcon_tree {
    stroke: var(--color-text);
  }

  .loadingIcon_cover {
    animation: loadingIcon_grow var(--loading-animation-parameters);
    fill: var(--color-page-background);
  }
}

@keyframes loadingIcon_grow {
  0%, 25% {
    transform: translate(0, 0);
  }

  70%, 100% {
    transform: translate(0, -160px);
  }
}

@keyframes loadingIcon_opacity {
  0%, 10% {
    opacity: 0;
  }

  25%, 80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}