:root {
  --dashedText-background: transparent;
  --dashedText-border-width: 1px;
  --dashedText-height: 1.5em; // This is to avoid cutting off bottom of text because of overflow: hidden
  --dashedText-shift: calc((var(--dashedText-height) - 1em) / 2);
}

.dashedText {
  line-height: var(--dashedText-height);
  height: var(--dashedText-height);
  display: inline-block;
  border-bottom: var(--dashedText-border-width) dashed;
  border-bottom-color: var(--dashedText-border-color, currentColor);
  transition: border-bottom-color var(--duration-color-transition);
  position: relative;
  top: calc(-1 * var(--dashedText-shift));
}

.dashedText_text {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
  top: var(--dashedText-shift);
  transition: text-shadow var(--duration-color-transition);

  .dashedText-truncate & {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dashedText-outline & {
    text-shadow:
            var(--dashedText-background) 1px 1px,
            var(--dashedText-background) -1px 1px,
            var(--dashedText-background) -1px -1px,
            var(--dashedText-background) 1px -1px,
            var(--dashedText-background) 1px 0,
            var(--dashedText-background) -1px 0,
            var(--dashedText-background) 0 -1px,
            var(--dashedText-background) 0 1px;
  }
}

.dashedText_text::after { // Otherwise Safari goes on to truncate any text, regardless of its width
  content: "";
  display: inline-block;
  --dashedText-extraSpacing: 1px;
  margin-left: calc(-1 * var(--dashedText-extraSpacing));
  width: var(--dashedText-extraSpacing);
}