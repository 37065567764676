@import "../../includes/values";

.aboutHeader {
  min-height: inherit;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutHeader_logo {
  fill: var(--color-text-inverse);
  height: 64px;
}

@media screen and (max-width: $val-threshold-width) {
  .aboutHeader_logo {
    height: 48px;
  }
}