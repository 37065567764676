@use 'sass:color';

$color-page-background: #FFFFFF;
$color-primary: #BA7851;
$color-primary-inactive: color.mix($color-primary, $color-page-background, 33%);
$color-secondary: $color-primary;
$color-secondary-inactive: #F6E2CD;
$color-transition-point: 25%;
$color-input-background: #F5F5F5;
$color-active: #5187BA;
$color-visited: #6B51BA;

:root {
  --color-page-background: #{$color-page-background};
  --color-page-background-transparent: #{transparentize($color-page-background, 1)};
  --color-input-background: #{$color-input-background};
  --color-input-background-semi-transparent: #{transparentize($color-input-background, 0.5)};
  --color-input-background-transparent: #{transparentize($color-input-background, 1)};
  --color-primary: #{$color-primary};
  --color-primary-transparent: #{transparentize($color-primary, 1)};
  --color-primary-light-background: #{transparentize($color-primary, 0.9)};
  --color-danger: var(--color-primary);
  --color-primary-inactive: #{$color-primary-inactive};
  --color-primary-inactive-transparent: #{transparentize($color-primary-inactive, 1)};
  --color-danger-inactive: var(--color-primary-inactive);
  --color-primary-transition: #{color.mix($color-primary, $color-primary-inactive, $color-transition-point)};
  --color-secondary: #{$color-secondary};
  --color-secondary-inactive: #{$color-secondary-inactive};
  --color-secondary-transition: #{color.mix($color-secondary, $color-secondary-inactive, $color-transition-point)};
  --color-secondary-inactive-to-primary: #{color.mix($color-secondary-inactive, $color-primary, 50%)};
  --color-text: #444444;
  --color-text-contrasted: #000000;
  --color-text-semicontrasted-background: #{color.mix(#000000, $color-input-background, 3%)};
  --color-text-contrasted-background: #{color.mix(#000000, $color-input-background, 10%)};
  --color-text-inverse: #FFFFFF;
  --color-text-inverse-highlighted: #FEE3B8;
  --color-text-secondary: #999999;
  --color-text-secondary-lighter: #A3A3A3;
  --color-shadow: rgba(0, 0, 0, 0.25);
  --color-active: #{$color-active};
  --color-active-light: #{color.mix($color-active, $color-page-background, 10%)};
  --color-active-transparent: #{transparentize($color-active, 1)};
  --color-visited: #{$color-visited};

  --duration-color-transition: 0.2s;
  --duration-transform-transition: 0.2s;
  --duration-opacity-transition: 0.15s;

  --size-shadow: 4px;
  --size-shadow-large-blur: 10px;
  --size-text-button-border-radius: 3px;
  --size-text-button-outline: 2px;
  --size-min-doc-width: var(--env-size-min-doc-width);
  --size-miniControl-width: 7px;
  --size-miniControl-outline-width: calc(12px + var(--size-miniControl-width));

  --block-shadow: 0 var(--size-shadow) var(--size-shadow) var(--color-shadow);
  --block-shadow-blurred: 0 var(--size-shadow) var(--size-shadow-large-blur) var(--color-shadow);
  --text-button-outline: 0 0 0 var(--size-text-button-outline) var(--color-active);

  --z-index-word-first: 3;
  --z-index-word-second: 2;
  --z-index-subrequest-options: 1;

  --font-family-default: 'Noto Serif', 'Times New Roman', serif;
  --font-size-default: 16px;
}

html {
  background-color: var(--color-page-background);
  font-family: var(--font-family-default);
  font-size: var(--font-size-default);
  line-height: 1.5;
  height: 100%;
  min-width: var(--size-min-doc-width);
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  touch-action: manipulation; // Eliminating the double-tap-to-zoom delay
}

body, #app-root {
  height: 100%;
}

#aria-alert-root {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

#app-root {
  height: 100%;
  position: relative;
  z-index: 1;
}

#popup-root {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

#cursor-root {
  position: fixed; // For cases when content's height overflows the screen
  z-index: 3;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;

  .cursor-move & {
    display: block;
    cursor: move;
  }
}

a {
  --a-outline-offset: var(--size-text-button-outline);
}

a:not(.a-colored) {
  transition: color var(--duration-color-transition);
  color: var(--color-active);
}

a:visited:not(:hover):not(.a-noVisited):not(.a-colored) {
  color: var(--color-visited);
}

a:hover:not(.a-colored) {
  color: var(--color-primary);
}

a:not(.a-noOutline):focus-visible {
  outline: var(--size-text-button-outline) solid var(--color-active);
  outline-offset: var(--a-outline-offset);
  -moz-outline-radius: var(--size-text-button-border-radius);
}

.nowrap {
  white-space: nowrap;
}