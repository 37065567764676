.selectOptions {
  position: relative;
  overflow: hidden;
}

.selectOptions_optionContainer {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  scroll-behavior: smooth;
  outline: none;

  .selectOptions-multi & {
    overflow-y: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.selectOptions_option {
  display: block;
  text-align: left;
}

.selectOptions-multi .selectOptions_fader, .selectOptions-willBeMulti .selectOptions_fader {
  display: block;
}

.selectOptions:not(.selectOptions-multi) {
  .selectOptions_option-multi {
    display: none;
  }
}

.selectOptions_fader {
  position: absolute;
  pointer-events: none;
  z-index: 2;
  left: 0;
  right: 0;
  display: none;
  transition: transform var(--duration-transform-transition);
}

.selectOptions_fader-top {
  top: 0;
  transform: translateY(-100%);
}

.selectOptions_fader-bottom {
  bottom: 0;
}

.selectOptions_fader-top .selectOptions_faderWrapper {
  transform: scaleY(-1);
}