.app {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
}

.app_top, .app_content {
  min-width: 0; // So they don't overflow the parent
}

@media print {
  .app_top {
    display: none;
  }
}