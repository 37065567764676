@import "../../includes/mixins";
@import "../../includes/values";

.customLanguages {
  --customLanguages-height: 28px;
  --customLanguages-half-horizontal-margin: 9px;
  --customLanguages-half-vertical-margin: calc((var(--header-height-control-b) - var(--customLanguages-height)) / 2);
  --customLanguages-button-horizontal-padding: 12px;
  --customLanguages-button-border-radius: 4px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: calc(-1 * var(--customLanguages-half-vertical-margin))
          calc(-1 * var(--customLanguages-half-horizontal-margin));
}

.customLanguages_buttonContainer {
  margin: var(--customLanguages-half-vertical-margin) var(--customLanguages-half-horizontal-margin);
  height: var(--customLanguages-height);
  line-height: var(--customLanguages-height);
}

.customLanguages_buttonContainer-extraLanguage {
  display: grid;
  grid-template-areas: "main";
}

.customLanguages_buttonContainer-extraLanguage .customLanguages_button {
  grid-area: main;
}

.customLanguages_buttonContainer-extraLanguage .customLanguages_extraText {
  grid-area: main;
}

.customLanguages_button {
  --customLanguages-border-color: var(--color-secondary-inactive);
  --customLanguages-border-width: 1px;
  font-size: 16px;
  height: 100%;
  justify-content: center;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  box-shadow: 0 0 0 var(--customLanguages-border-width) var(--customLanguages-border-color);

  &:not([disabled]) {
    cursor: pointer;
  }

  @include outline {
    --customLanguages-border-color: var(--color-active);
    --customLanguages-border-width: 3px;
  }
}

.customLanguages_button, .customLanguages_extraText {
  padding: 0 var(--customLanguages-button-horizontal-padding);
  border-radius: var(--customLanguages-button-border-radius);
  text-align: center;
  transition-property: color, background-color;
  transition-duration: var(--duration-color-transition);
  background: none;
  overflow: hidden; // This is to clip .customLanguages_dashedTextWrapper
}

.customLanguages_button {
  color: var(--color-secondary-inactive);
}

.customLanguages_button-active {
  background-color: var(--color-secondary-inactive);
  color: var(--color-text);
}

.customLanguages_button:not(.customLanguages_button-active) {
  @include duplicateWithHover('&:hover', '&:focus-visible') {
    background-color: var(--color-secondary-inactive-to-primary);
    color: var(--color-text);
  }
}

.customLanguages_buttonTextContainer {
  display: grid;
  grid-template-areas: "main";
}

.customLanguages_buttonText {
  grid-area: main;
  white-space: nowrap;
}

.customLanguages_buttonText-hidden {
  visibility: hidden;
}

.customLanguages_buttonText, .customLanguages_extraText {
  // This was made a separate variable to combat SASS build failing due to deep nesting
  --customLanguages-max-header-width: calc(
      max(var(--size-min-doc-width), 100vw - 32px /* potential scrollbar */) - 2 * #{$val-header-horizontal-padding}
  );
  max-width: calc(
    min(
        var(--customLanguages-max-header-width),
        #{$val-header-control-width}
    ) - 2 * var(--customLanguages-button-horizontal-padding)
  );
}

.customLanguages_extraButtonContainer {
  display: inline-block;
}

.customLanguages_extraButton {
  --customLanguages-extraButton-padding: 2px;
  font-size: 16px;
  color: inherit;
  background: none;
  padding: 0 var(--customLanguages-extraButton-padding);
  margin: 0 calc(-1 * var(--customLanguages-extraButton-padding));
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: var(--size-text-button-border-radius);
  display: inline-block;
  pointer-events: auto;

  @include outline(-2px) {
    box-shadow: var(--text-button-outline);
  }
}

.customLanguages_dashedTextWrapper {
  position: relative;
}

.customLanguages_dashedTextWrapper::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  // This is to ensure that the user would not inadvertently trigger main button's click/hover while clicking/hovering
  // slightly above or below the extraButton
  top: calc(-1 * var(--customLanguages-height));
  bottom: calc(-1 * var(--customLanguages-height));
}

.customLanguages_extraButtonTextContainer {
  display: inline-block;
  margin: -2px 0; // So that focused outline on the button fits into its parent button
}

.customLanguages_extraText {
  display: inline-block;
  white-space: nowrap;
  pointer-events: none;
  --customLanguages-extraText-color: var(--color-text-inverse);
  --customLanguages-extraText-background: var(--color-primary);
  color: var(--customLanguages-extraText-color);
  background-color: var(--customLanguages-extraText-background);
  --dashedText-background: var(--customLanguages-extraText-background);
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: var(--customLanguages-button-horizontal-padding);
    // Setting width: 0 and spread for the shadow doesn't work in iOS
    right: calc(-1 * var(--customLanguages-button-horizontal-padding));
    top: 0;
    bottom: 0;
    transition-property: box-shadow, opacity;
    transition-duration: var(--duration-color-transition);
    box-shadow: // Gradient is not transitionable
            calc(-1 * var(--customLanguages-button-horizontal-padding) / 2)
            0
            calc(var(--customLanguages-button-horizontal-padding) / 2)
            var(--customLanguages-extraText-background);
  }

  &:focus-within::after {
    visibility: hidden; // So that focused extra button outline is not faded
    opacity: 0; // So that at least unfocusing is animated
  }

  & .customLanguages_extraButton {
    color: var(--customLanguages-extraText-color);
    transition: color var(--duration-color-transition);

    @include duplicateWithHover('&:hover', '&:focus-visible') {
      color: var(--color-secondary-inactive);

      .customLanguages_button + & {
        color: var(--color-text-inverse);
      }

      @include duplicateWithHover(
        '.customLanguages_button-active + &',
        '.customLanguages_button:hover + &',
        '.customLanguages_button:focus-visible + &'
      ) {
        color: var(--color-text-contrasted);
      }
    }
  }

  .customLanguages_button + & {
    --customLanguages-extraText-color: var(--color-secondary-inactive);
  }

  @include duplicateWithHover(
    '.customLanguages_button:not(.customLanguages_button-active):hover + &',
    '.customLanguages_button:not(.customLanguages_button-active):focus-visible + &'
  ) {
    --customLanguages-extraText-color: var(--color-text);
    --customLanguages-extraText-background: var(--color-secondary-inactive-to-primary);
  }

  .customLanguages_button-active + & {
    --customLanguages-extraText-color: var(--color-text);
    --customLanguages-extraText-background: var(--color-secondary-inactive);
  }
}