.blank {
  --blank-min-height: 60px;
  --blank-effective-vh: max(1vh, calc(var(--blank-min-height) / 100));
  display: flex;
  width: 100%;
  height: 100%;
  min-height: var(--blank-min-height);
  position: relative;
  z-index: -1; // So that the header shadow is above
  overflow: hidden;
  background-color: white; // To make the (semitransparent) background image lighter
}

.blank_imageContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #784e2a; // The average color of the background image (the fallback is also of this color)
    opacity: 1;
    transition: opacity var(--duration-opacity-transition) ease-in; // 'ease-in' matches better with the image's 'ease'

    .blank-loaded & {
      opacity: 0;
    }
  }
}

.blank_image {
  display: block;
  position: absolute;
  width: 100%;
  height: max(100vh, 100%, var(--blank-min-height)); // 100vh is so that it doesn't change size when switching tabs
  // 100% is to mitigate an iOS bug: the viewport is taller than 100vh for screens narrower than REACT_APP_MIN_DOC_WIDTH
  object-fit: cover;
  object-position: right bottom;
  user-select: none;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity var(--duration-opacity-transition);

  .blank-loaded & {
    opacity: 1;
  }
}

.blank_logo {
  fill: var(--color-text-inverse);
  display: block;
  position: absolute;
  z-index: 1;
  width: calc(100px + 10 * min(1vw, var(--blank-effective-vh)));
  right: calc(10px + 1vw);
  bottom: calc(10px + var(--blank-effective-vh));
}