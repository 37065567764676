@use "sass:string";

@mixin ruleWithHover() {
  @media (hover: hover) {
    @content;
  }
}

@mixin duplicateWithHover($selectors...) {
  // This was introduced to deal with focus-visible in Safari. The approach is based on the behavior mentioned here:
  // https://stackoverflow.com/questions/13816764/invalid-css-selector-causes-rule-to-be-dropped-what-is-the-rationale
  @for $i from 0 to length($selectors) {
    $selector: nth($selectors, $i + 1);
    @if string.index($selector, ":hover") {
      @include ruleWithHover {
        #{$selector} {
          @content;
          @include mark($i);
        }
      }
    } @else {
      #{$selector} {
        @content;
        @include mark($i);
      }
    }
  }
}

@mixin mark($mark) {
  // So that the SASS compiler doesn't group the selectors (there seems to be no cleaner way to achieve that)
  --_: #{$mark};
}

@mixin outline($offset: '', $outlineNone: true) {
  & {
    @if $offset != '' {
      outline-offset: $offset;
    }
  }

  &, .not-a-real-class:focus-visible { // The latter part is is used to suppress outline: none in browsers that
    outline: none;                     // do not support :focus-visible (both of the selectors are ignored in this case)
  }

  &:focus-visible {
    @if ($outlineNone) {
      outline: none;
    }
    @content;
  }
}