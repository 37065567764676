@import "../../includes/values";

.header {
  --header-height-top: 20px;
  --header-height-languages: 16px;
  --header-height-languages-gap: 20px;
  --header-height-control-a: 36px;
  --header-height-control-gap: 14px;
  --header-height-control-b: 36px;
  --header-height-bottom: 22px;

  min-height: calc(
          var(--header-height-top) + var(--header-height-languages) + var(--header-height-languages-gap) +
          var(--header-height-control-a) + var(--header-height-control-gap) + var(--header-height-control-b) +
          var(--header-height-bottom)
  );
  box-shadow: var(--block-shadow);
}

@media screen and (max-width: $val-threshold-width) {
  .header {
    --header-height-top: 12px;
    --header-height-languages-gap: 18px;
    --header-height-control-gap: 12px;
    --header-height-bottom: 16px;

    min-height: calc(
            var(--header-height-top) + var(--header-height-languages) + var(--header-height-languages-gap) +
            var(--header-height-control-a) + var(--header-height-bottom)
    );
  }
}