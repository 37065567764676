#word-preloader-root {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.wordMeasurer_container {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
}

.wordMeasurer_word {
  position: absolute;
  left: 0;
  top: 0;
  width: max-content;
  height: max-content;
}