@import "../../includes/menu";
@import "../../includes/mixins";
@import "../../includes/values";

.menu {
  --menu-height: #{$menu-height};
  --menu-font-size: #{$menu-font-size};
  --menu-font-weight: #{$menu-font-weight};
  --menu-gap: #{$menu-gap};

  --menu-primary-item-width-multiplier: #{$menu-primary-item-width-multiplier};
  --menu-about-width-multiplier: #{$menu-about-width-multiplier};
  --menu-primary-item-width: calc(var(--menu-primary-item-width-multiplier) * var(--menu-font-size));
  --menu-etymology-width: var(--menu-primary-item-width);
  --menu-relation-width: var(--menu-primary-item-width);
  --menu-cognates-width: var(--menu-primary-item-width);
  --menu-about-width: calc(var(--menu-about-width-multiplier) * var(--menu-font-size));
  --menu-skew-shift: calc(#{$menu-skew-shift-multiplier} * var(--menu-height));

  --menu-main-display: inline-block;
  --menu-secondary-display: none;

  --menu-desktop-display: var(--menu-main-display);
  --menu-mobile-display: var(--menu-secondary-display);

  height: var(--menu-height);
  line-height: var(--menu-height);
  overflow: hidden;
}

.menu_container {
  font-weight: var(--menu-font-weight);
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-areas: ". etymology . relation . cognates . . about";
  grid-template-columns: 1fr
                         var(--menu-etymology-width) var(--menu-gap)
                         var(--menu-relation-width) var(--menu-gap)
                         var(--menu-cognates-width) var(--menu-gap) 1fr
                         var(--menu-about-width);
}

.menu_item {
  display: inline-block;
  text-align: center;
  position: relative;
  white-space: nowrap;
  transform: skew(-$menu-skew);
  color: var(--color-text);
  text-decoration-color: var(--color-text);
  transition-property: color, text-decoration-color;
  transition-duration: var(--duration-color-transition);
}

@include ruleWithHover {
  .menu_item:not(.menu_item-active):hover {
    color: var(--color-text-contrasted);
  }
}

.menu_item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: -$menu-border-size;
  right: 0;
  bottom: -$menu-border-size;
  z-index: -1;
  border-width: $menu-border-size;
  border-style: solid;
  border-color: var(--color-primary);
  background-color: var(--color-primary-inactive);
  transition: background-color var(--duration-color-transition);
}

@include ruleWithHover {
  .menu_item:not(.menu_item-active):hover::before {
    background-color: var(--color-primary-transition);
  }
}

@include ruleWithHover {
  .menu_item-about:not(.menu_item-active):hover::before {
    background-color: var(--color-secondary-transition);
  }
}

.menu_item-etymology {
  grid-area: etymology;
}

.menu_item-relation {
  grid-area: relation;
}

.menu_item-cognates {
  grid-area: cognates;
}

.menu_item-about {
  grid-area: about;
  text-align: left;
  margin-right: calc(-1 * var(--menu-skew-shift) - #{$menu-border-size});
}

.menu_item-about::before {
  border-color: var(--color-secondary);
  background-color: var(--color-secondary-inactive);
}

.menu_item-active {
  color: var(--color-text-inverse);
  text-decoration-color: transparent;
}

.menu_item-active::before {
  background-color: var(--color-primary);
}

.menu_item-about.menu_item-active::before {
  background-color: var(--color-secondary);
}

.menu_heading {
  font-size: var(--menu-font-size);
  font-weight: inherit;
  margin: 0;
  text-decoration-color: inherit;
  cursor: default;
}

.menu_link {
  display: inline-block;
  height: var(--menu-height);
  width: 100%;
  font-size: var(--menu-font-size);
  color: inherit;
  text-decoration: none; // This will go to .menu_text instead
  text-decoration-color: inherit;
  outline-offset: -3px;

  @include outline($outlineNone: false) {
    outline: 3px solid var(--color-active);
  }
}

.menu_text {
  display: inline-block;
  transform: skew($menu-skew);
  text-decoration: underline;
  text-decoration-color: inherit;
}

.menu_item-about .menu_text {
  margin-left: var(--menu-skew-shift);
}

.menu_heading-desktop, .menu_link-desktop {
  display: var(--menu-desktop-display);
}

.menu_heading-mobile, .menu_link-mobile {
  display: var(--menu-mobile-display);
}

@media screen and (max-width: $val-threshold-width) {
  .menu {
    --menu-height: #{$menu-m-height};
    --menu-font-size: #{$menu-m-font-size};
    --menu-font-weight: #{$menu-m-font-weight};
    --menu-gap: #{$menu-m-gap};
    --menu-primary-item-width: auto;
    --menu-about-width: auto;
    --menu-desktop-display: var(--menu-secondary-display);
    --menu-mobile-display: var(--menu-main-display);
  }

  .menu_text {
    margin: 0 $menu-m-padding;
  }

  .menu_item-about .menu_text {
    margin: 0 #{$menu-m-about-padding-right} 0 #{$menu-m-about-padding-left};
  }
}