@import "../../includes/mixins";

.listEditorItem {
  --listEditorItem-font-size: 16px;
  --listEditorItem-height: 36px;
  --listEditorItem-handle-height: 12px;
  --listEditorItem-gap: 8px;
  --listEditorItem-border-radius: 4px;
  --listEditorItem-border-width: 2px;
  --listEditorItem-total-height: calc(var(--listEditorItem-height) + 2 * var(--listEditorItem-border-width));
  --listEditorItem-content-vertical-margin: calc(-1 * var(--listEditorItem-border-width));

  display: block;
  position: relative;
  font-size: var(--listEditorItem-font-size);
  height: var(--listEditorItem-total-height);
  outline: none;
  margin: 0 calc(-1 * var(--listEditorItem-border-width));
  transition: opacity var(--duration-opacity-transition);
  pointer-events: none; // So that one language's container doesn't cover another language's content while reordering

  // So that cursor doesn't become 'text' when it is over an inactive item or an active item just after reordering:
  cursor: default;

  z-index: 1;

  &:first-of-type {
    margin-top: calc(-1 * var(--listEditorItem-border-width));
  }

  &:last-of-type {
    margin-bottom: calc(-1 * var(--listEditorItem-border-width));
  }
}

.listEditorItem-focused {
  z-index: 2;
}

.listEditorItem-justAdded {
  animation: listEditorItem-added var(--duration-transform-transition);
  overflow: hidden;
}

.listEditorItem-inactive {
  opacity: 0.5;
}

.listEditorItem-removed {
  animation: listEditorItem-removed var(--duration-transform-transition) forwards;
  overflow: hidden;
}

.listEditorItem_wrapper {
  position: relative;
  width: 100%;
  pointer-events: auto;
  height: var(--listEditorItem-total-height);
  // Height has to be set explicitly because offsetHeight for this element is queried from JS during initial animation

  transition-property: transform;
  transition-duration: 0s;
  // Doing this instead of setting the whole transition in ".listEditorItem-transition &" is required for iOS browsers
  // to work correctly when a transition is repeated twice for a language (say, when it's disturbed in place)

  .listEditorItem-transition & {
    transition-duration: var(--duration-transform-transition);
  }
}

.listEditorItem_content {
  position: absolute;
  left: var(--listEditorItem-border-width);
  right: var(--listEditorItem-border-width);
  top: var(--listEditorItem-border-width);
  bottom: var(--listEditorItem-border-width);
  background-color: var(--color-input-background);

  display: grid;
  grid-template-areas: "handle title buttons";
  grid-template-columns: max-content 1fr max-content;
  grid-gap: var(--listEditorItem-gap);

  padding: 0 var(--listEditorItem-gap);
  border-radius: var(--listEditorItem-border-radius);

  transition: background-color var(--duration-color-transition);

  .listEditorItem-focused & {
    box-shadow: 0 0 0 var(--listEditorItem-border-width) var(--color-active);
  }

  @include duplicateWithHover(
    '.listEditorItem-focused &',
    '.listEditorItem-drag:not(.listEditorItem-disabled) &',
    '.listEditorItem:hover:not(.listEditorItem-disabled) &', // So that the relevant item is apparent when the
    '.listEditorItem-focusVisibleWithin &'                   // user is about to click a button on the right
  ) {
    background-color: var(--color-secondary-inactive);
  }
}

.listEditorItem-drag:not(.listEditorItem-disabled),
.listEditorItem:focus /* So that the cursor doesn't change back and forth when an item is dropped */ {
  cursor: move;
}

.listEditorItem_dragHandle {
  display: flex;
  align-items: center;
  padding: 0 var(--listEditorItem-gap);
  margin: var(--listEditorItem-content-vertical-margin) calc(-1 * var(--listEditorItem-gap));
  height: var(--listEditorItem-total-height);
  touch-action: none;
}

.listEditorItem_dragHandleIcon {
  height: var(--listEditorItem-handle-height);
  fill: var(--color-text-secondary);
}

.listEditorItem_title {
  height: var(--listEditorItem-height);
  line-height: var(--listEditorItem-height);
  padding: var(--listEditorItem-border-width) 0;
  margin: var(--listEditorItem-content-vertical-margin) 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  touch-action: none;
}

.listEditorItem_buttonsContainer {
  display: flex;
  align-items: center;
  transition: opacity var(--duration-opacity-transition);

  .listEditorItem-inactive & {
    opacity: 0;
  }
}

.listEditorItem_button {
  --listEditorItem-button-icon-size: var(--size-miniControl-outline-width);
  --listEditorItem-button-background-color: var(--color-active);

  height: 100%;

  background: none;
  border: none;
  border-radius: var(--listEditorItem-border-radius);
  padding: 0 var(--listEditorItem-gap);
  margin: 0 calc(-1 * var(--listEditorItem-gap));
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition-property: opacity, margin-right;
  transition-duration: min(var(--duration-opacity-transition), var(--duration-transform-transition));

  .listEditorItem_button + & {
    margin-left: var(--listEditorItem-gap);
  }

  &.listEditorItem_button-hidden {
    opacity: 0;
    margin-right: calc(-1 * var(--listEditorItem-button-icon-size) - 3 * var(--listEditorItem-gap));
    pointer-events: none; // Visibility is not being used because it interferes with the parent's visibility
  }

  .listEditorItem-disabled & {
    // This is to prevent the "up" button from briefly flashing in Chrome/Safari after the animation is complete
    // (only happens when mouse-clicked; apparently, a stale hover issue)
    pointer-events: none;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  @include outline(-2px) {
    box-shadow: 0 0 0 var(--listEditorItem-border-width) var(--color-active);
  }

  &.listEditorItem_button-remove {
    --listEditorItem-button-background-color: var(--color-primary);
  }
}

.listEditorItem_buttonIconContainer {
  width: var(--listEditorItem-button-icon-size);
  height: var(--listEditorItem-button-icon-size);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background-color var(--duration-color-transition);
}

.listEditorItem_buttonIcon {
  transition: fill var(--duration-color-transition);
  fill: var(--color-text);

  .listEditorItem_button-up & {
    width: calc(var(--size-miniControl-width) + 2px);
    height: calc(var(--size-miniControl-width) - 2px);
  }

  .listEditorItem_button-remove & {
    width: var(--size-miniControl-width);
    height: var(--size-miniControl-width);
  }
}

@media (hover: hover) {
  .listEditorItem_button:focus-visible {
    .listEditorItem_buttonIcon {
      fill: var(--color-input-background);
      @include mark(0);
    }

    .listEditorItem_buttonIconContainer {
      background-color: var(--listEditorItem-button-background-color);
      @include mark(0);
    }
  }

  .listEditorItem:not(.listEditorItem-disabled) /* The preceding is to avoid the re-hover effect when the topmost "up"
  button (the one on the second item) is clicked */ .listEditorItem_button:not([disabled]):hover {
    .listEditorItem_buttonIconContainer {
      background-color: var(--listEditorItem-button-background-color);
      @include mark(1);
    }

    .listEditorItem_buttonIcon {
      fill: var(--color-input-background);
      @include mark(1);
    }
  }
}

@keyframes listEditorItem-added {
  from {
    height: 0;
    opacity: 0;
  }
}

@keyframes listEditorItem-removed {
  to {
    height: 0;
    opacity: 0;
  }
}