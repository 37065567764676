.sources_set + .sources_set {
  &:before {
    content: "";
    display: block;
    height: 1px;
    background-color: var(--popup-border-color);
    margin: 1.2em 0;
  }
}

.sources_item + .sources_item {
  margin-top: 1.6em;
}

.sources_relation {
  display: block;
  text-align: center;
}

.sources_word {
  font-weight: bold;
}

.sources_languages {
  color: var(--color-text-secondary-lighter);
  line-height: 1;
  margin-bottom: 0.2em;
}

.sources_language {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.04em;
  font-weight: bold;
}