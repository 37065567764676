@import "../../includes/values";

.mainHeader {
  background-color: var(--color-primary);
  display: grid;
  grid-template-areas: ". top           ."
                       ". languages     ."
                       ". languages-gap ."
                       ". control-1     ."
                       ". control-gap   ."
                       ". control-2     ."
                       ". bottom        .";
  grid-template-columns:
          minmax($val-header-horizontal-padding, 1fr)
          minmax(auto, $val-header-control-width)
          minmax($val-header-horizontal-padding, 1fr);
  grid-template-rows:
          var(--header-height-top) var(--header-height-languages) var(--header-height-languages-gap)
          var(--header-height-control-a) var(--header-height-control-gap)
          minmax(min-content, var(--header-height-control-b)) var(--header-height-bottom);
  height: 100%;
  align-items: center;
}

.mainHeader_languagesOfInterest {
  grid-area: languages;
  justify-self: center;
}

.mainHeader_word {
  position: relative;
}

.mainHeader_word-first {
  z-index: var(--z-index-word-first);
  grid-area: control-1;

  .mainHeader-etymology & {
    top: calc((var(--header-height-control-gap) + var(--header-height-control-b)) / 2);
  }
}

.mainHeader_word-second {
  z-index: var(--z-index-word-second);
  grid-area: control-2;
}

.mainHeader_customLanguages {
  grid-area: control-2;
  align-self: end;

  .mainHeader:not(.mainHeader-cognates) & {
    display: none;
  }
}

@media screen and (max-width: $val-threshold-width) {
  .mainHeader-etymology {
    --header-height-control-gap: 0;
    --header-height-control-b: 0;
  }
}