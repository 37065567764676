@import "../../includes/mixins";

.horizontalScroller {
  --horizontalScroller-extra-space: calc(var(--size-text-button-outline) + 1px);
  // 1px is so that scaling does not create artifacts
  --horizontalScroller-calculated-background: var(--horizontalScroller-background, white);
  --horizontalScroller-calculated-background-transparent:
    var(--horizontalScroller-background-transparent, rgba(255, 255, 255, 0));
  --horizontalScroller-option-calculated-vertical-padding: var(--horizontalScroller-option-vertical-padding, 0px);
  --horizontalScroller-option-calculated-horizontal-padding: var(--horizontalScroller-option-horizontal-padding, 0px);
  --horizontalScroller-option-calculated-horizontal-margin: var(--horizontalScroller-option-horizontal-margin, 0px);

  position: relative;
  overflow: hidden;

  padding: var(--horizontalScroller-extra-space) 0;
  margin: calc(-1 * var(--horizontalScroller-extra-space)) 0;
}

.horizontalScroller_options {
  white-space: nowrap;
  text-align: center;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;

  padding: var(--horizontalScroller-extra-space) 0;
  margin: calc(-1 * var(--horizontalScroller-extra-space)) 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.horizontalScroller_option {
  border-radius: var(--size-text-button-border-radius);
  display: inline-block;

  padding: var(--horizontalScroller-option-calculated-vertical-padding)
           var(--horizontalScroller-option-calculated-horizontal-padding);
  margin: 0 calc(var(--horizontalScroller-option-calculated-horizontal-margin) / 2);

  background-color: transparent;
  color: var(--color-text);
  transition-property: color, background-color, text-decoration-color;
  transition-duration: var(--duration-color-transition);
  text-decoration: underline;
  text-decoration-color: var(--color-text);
  text-underline-offset: 1px; // At the time of writing this gives the desired effect in Chrome while
                              // leaving the underline where it was in other browsers
  cursor: pointer;

  &:first-child, &:last-child {
    margin: 0 var(--horizontalScroller-extra-space);
  }

  @include outline(-2px) {
    box-shadow: var(--text-button-outline);
  }

  &.horizontalScroller_option-nonselected {
    @include duplicateWithHover('&:hover', '&:focus-visible') {
      color: var(--color-text-contrasted);
      text-decoration-color: var(--color-text-contrasted);
    }
  }

  &.horizontalScroller_option-selected {
    text-decoration-color: transparent;
    cursor: default;
    color: var(--color-text-inverse);
    background-color: var(--color-primary);
  }
}

.horizontalScroller_optionsWrapper {
  display: inline-block;
}

.horizontalScroller_optionList {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.horizontalScroller_optionListItem {
  display: inline-block;
  text-align: center;
}

.horizontalScroller_marker {
  display: inline-block;
  --horizontalScroller-marker-width: 1px;
  width: var(--horizontalScroller-marker-width);
  height: 1px;
  visibility: hidden;

  &.horizontalScroller_marker-left {
    margin-right: calc(-1 * var(--horizontalScroller-marker-width));
  }

  &.horizontalScroller_marker-right {
    margin-left: calc(-1 * var(--horizontalScroller-marker-width));
  }
}

.horizontalScroller_control {
  position: absolute;
  top: 0;
  bottom: 0;
  width: auto;
  padding: 0 0.5ch;
  background-color: var(--horizontalScroller-calculated-background);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--color-text);
  transition-property: color, opacity, visibility;
  transition-duration: var(--duration-color-transition),
                       var(--duration-opacity-transition),
                       var(--duration-opacity-transition);

  &-hidden {
    opacity: 0;
    visibility: hidden;
  }

  &-visible {
    opacity: 1;
    visibility: visible;
  }

  @include ruleWithHover {
    &:hover {
      color: var(--color-text-contrasted);
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3ch;
    pointer-events: none;
  }
}

.horizontalScroller_control-left {
  left: 0;

  &::after {
    left: 100%;
    background: linear-gradient(90deg,
      var(--horizontalScroller-calculated-background),
      var(--horizontalScroller-calculated-background-transparent));
  }
}

.horizontalScroller_control-right {
  right: 0;

  &::after {
    right: 100%;
    background: linear-gradient(270deg,
            var(--horizontalScroller-calculated-background),
            var(--horizontalScroller-calculated-background-transparent));
  }
}

.horizontalScroller_arrow {
  width: 14px;
  fill: currentColor;
  display: inline-block;
  position: relative;
  top: 1px;
}

.horizontalScroller_arrow-left {
  transform: scaleX(-1);
}

@media print {
  .horizontalScroller_option-nonselected, .horizontalScroller_control {
    display: none;
  }
}