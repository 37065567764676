.softAlert {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  font-size: 24px;
  color: var(--color-text-contrasted);
  text-align: center;
  padding: 48px;
  box-sizing: border-box;
}

.softAlert {
  &::before, &::after {
    content: "";
    display: block;
  }

  &::before {
    flex-grow: 1;
  }

  &::after {
    flex-grow: 2;
  }
}