.mainContent {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: auto minmax(200px, 1fr);
  animation: mainContent-fadein var(--duration-opacity-transition);
}

.mainContent_options {
  min-width: 0; // So it doesn't overflow the parent
  position: relative;
  z-index: var(--z-index-subrequest-options); // So that its shadow is over the content
}

@keyframes mainContent-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}