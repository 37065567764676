@import "../../includes/mixins";

.multiArrow_backgroundContainer, .multiArrow_primitiveContainer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.multiArrow_backgroundContainer {
  z-index: 0;
}

.multiArrow_backgroundContainerGroup {
  // This is needed because Safari cannot properly animate opacity of the SVG element itself
  transition: opacity var(--duration-opacity-transition);
  // So that there is no flash when moving cursor between background/secondary (also see below):
  transition-timing-function: ease-in;
  opacity: 0;
}

.multiArrow_primitiveContainer {
  z-index: 1;
  transition: z-index var(--duration-color-transition);
}

.multiArrow_secondaries, .multiArrow_backgrounds {
  fill: none;
  stroke: var(--color-active-light);
  stroke-width: calc(2 * var(--multiArrow-background-half-width));
  stroke-linecap: round;
  stroke-linejoin: round;
}

.multiArrow_secondaries {
  opacity: 0.75;
}

.multiArrow_backgrounds {
  pointer-events: all;

  .multiArrow_box {
    stroke-width: 0;
    fill: transparent;
  }
}

.multiArrow_primitives {
  stroke: var(--color-text-secondary-lighter);
  fill: none;
  stroke-width: 1px;
  stroke-linecap: butt;
  transition: stroke var(--duration-color-transition);
}

.multiArrow_arrow-dashed {
  stroke-dasharray: 4 3;
  stroke-dashoffset: 3;
}

.multiArrow {
  outline: none;
  position: absolute;
  pointer-events: none;

  @media not print {
    @include duplicateWithHover('&:hover', '&.multiArrow-focused', '&.multiArrow-activePopup') {
      .multiArrow_backgroundContainerGroup {
        opacity: 1;
        // So that there is no flash when moving cursor between background/secondary (also see above):
        transition-timing-function: ease-out;
      }

      .multiArrow_primitiveContainer {
        z-index: 2;
      }

      .multiArrow_primitives {
        stroke: var(--color-text-contrasted);
      }
    }
  }
}

.multiArrow_popupContainer {
  overflow-wrap: break-word;
}