@import "../../includes/values";

#popup-root:empty::after {
  // This is for screen readers to provide confirmation for closed popups as well as to address VoiceOver not
  // reading an identical live message for a second time (related to https://core.trac.wordpress.org/ticket/36853)
  content: var(--popup-closed-label, "");
  white-space: nowrap;
  display: block;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: 100%;
  right: 100%;
}

.popupWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: max(100%, var(--popupWrapper-min-height, 0px));
}

.popup-popover {
  --popup-border-width: 3px;
  --popup-anchor-spacing: 15px;
  --popup-arrow-size: 10px;
}

.popup-tooltip {
  --popup-border-width: 2px;
  --popup-anchor-spacing: 12px;
  --popup-arrow-size: 8px;
}

.popup {
  // These default values will be overridden by JavaScript:
  --popup-anchor-left: 0px;
  --popup-anchor-right: 0px;
  --popup-anchor-top: 0px;
  --popup-anchor-bottom: 0px;
  --popup-current-anchor-left: 0px;
  --popup-current-anchor-right: 0px;
  --popup-current-anchor-top: 0px;
  --popup-current-anchor-bottom: 0px;
  --popup-computed-width: 0px;
  --popup-computed-height: 0px;

  --popup-padding: 10px;
  --popup-border-radius: 6px;
  --popup-background-color: var(--color-page-background);
  --popup-border-color: var(--color-secondary-inactive);
  --popup-border-style: solid;
  --popup-max-width: calc(var(--size-min-doc-width) - 2 * var(--popup-horizontal-margin));

  --popup-arrow-actual-size: calc(var(--popup-arrow-size) + var(--popup-border-width));
  --popup-arrow-side: calc(var(--popup-arrow-actual-size) * 1.4142);
  --popup-arrow-border-radius: 3px;

  --popup-arrowContainer-along: calc(2 * var(--popup-arrow-actual-size));
  --popup-arrowContainer-across: calc(var(--popup-arrow-size) + var(--popup-border-width));
  --popup-arrowContainer-min-margin: calc(var(--popup-border-width) + var(--popup-border-radius));

  // We want the popup to align with the inputs at minimum document width
  --popup-horizontal-margin: calc(#{$val-header-horizontal-padding} - var(--popup-border-width));
  --popup-vertical-margin: max(var(--size-shadow) + var(--size-shadow-large-blur), var(--popup-horizontal-margin));

  &.popup-fixed {
    --popup-width: var(--popup-max-width);
  }

  &.popup-shrink {
    --popup-width: max-content;
  }

  // These are for consistent initial render only and to be overridden by one of the popup-top/bottom/left/right classes
  --popup-left: 0px;
  --popup-top: 0px;
  --popup-right: auto;
  --popup-bottom: auto;
  --popup-arrowContainer-width: 0px;
  --popup-arrowContainer-height: 0px;
  --popup-arrowContainer-left: 0px;
  --popup-arrowContainer-top: 0px;
  --popup-arrowContainer-right: auto;
  --popup-arrowContainer-bottom: auto;
  --popup-arrowContainer-arrow-left: 0px;
  --popup-arrowContainer-arrow-top: 0px;
  --popup-arrowContainer-arrow-right: auto;
  --popup-arrowContainer-arrow-bottom: auto;
  --popup-arrowContainer-arrow-rotation: 0deg;

  position: absolute;
  z-index: 1; // So that two popups displayed at the same time are stacked
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  overflow: hidden; // While measurements are taken
  pointer-events: none;

  animation: popup-fadein var(--duration-opacity-transition);

  &.popup-top, &.popup-bottom, &.popup-left, &.popup-right {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  &.popup-top, &.popup-bottom {
    --popup-left: clamp(var(--popup-horizontal-margin),
                        calc((var(--popup-anchor-left) + var(--popup-anchor-right) - var(--popup-computed-width)) / 2),
                        calc(100% - var(--popup-horizontal-margin) - var(--popup-computed-width)));
    --popup-right: auto;

    --popup-arrowContainer-width: var(--popup-arrowContainer-along);
    --popup-arrowContainer-height: var(--popup-arrowContainer-across);
    --popup-arrowContainer-left: clamp(calc(var(--popup-left) + var(--popup-arrowContainer-min-margin)),
                                       calc((var(--popup-current-anchor-left) + var(--popup-current-anchor-right)) / 2
                                            - var(--popup-arrowContainer-width) / 2),
                                       calc(var(--popup-left) + var(--popup-computed-width)
                                            - var(--popup-arrowContainer-min-margin)
                                            - var(--popup-arrowContainer-width)));
    --popup-arrowContainer-right: auto;
    --popup-arrowContainer-arrow-left: calc((var(--popup-arrowContainer-width) - var(--popup-arrow-side)) / 2);
    --popup-arrowContainer-arrow-right: auto;
  }

  &.popup-top {
    --popup-top: auto;
    --popup-bottom: calc(100% - var(--popup-anchor-top) + var(--popup-anchor-spacing));
    --popup-arrowContainer-top: auto;
    --popup-arrowContainer-bottom:
            calc(var(--popup-bottom) + var(--popup-border-width) - var(--popup-arrowContainer-height));
    --popup-arrowContainer-arrow-top: calc(-1 * var(--popup-arrow-side) / 2);
    --popup-arrowContainer-arrow-bottom: auto;
    --popup-arrowContainer-arrow-rotation: -135deg;
  }

  &.popup-bottom {
    --popup-top: calc(var(--popup-anchor-bottom) + var(--popup-anchor-spacing));
    --popup-bottom: auto;
    --popup-arrowContainer-top: calc(var(--popup-top) + var(--popup-border-width) - var(--popup-arrowContainer-height));
    --popup-arrowContainer-bottom: auto;
    --popup-arrowContainer-arrow-top: auto;
    --popup-arrowContainer-arrow-bottom: calc(-1 * var(--popup-arrow-side) / 2);
    --popup-arrowContainer-arrow-rotation: 45deg;
  }

  &.popup-left, &.popup-right {
    --popup-top: clamp(var(--popup-vertical-margin),
                       calc((var(--popup-anchor-top) + var(--popup-anchor-bottom) - var(--popup-computed-height)) / 2),
                       calc(100% - var(--popup-vertical-margin) - var(--popup-computed-height)));
    --popup-bottom: auto;

    --popup-arrowContainer-width: var(--popup-arrowContainer-across);
    --popup-arrowContainer-height: var(--popup-arrowContainer-along);
    --popup-arrowContainer-top: clamp(calc(var(--popup-top) + var(--popup-arrowContainer-min-margin)),
                                      calc((var(--popup-current-anchor-top) + var(--popup-current-anchor-bottom)) / 2
                                           - var(--popup-arrowContainer-height) / 2),
                                      calc(var(--popup-top) + var(--popup-computed-height)
                                           - var(--popup-arrowContainer-min-margin)
                                           - var(--popup-arrowContainer-height)));
    --popup-arrowContainer-bottom: auto;
    --popup-arrowContainer-arrow-top: calc((var(--popup-arrowContainer-height) - var(--popup-arrow-side)) / 2);
    --popup-arrowContainer-arrow-bottom: auto;
  }

  &.popup-left {
    --popup-left: auto;
    --popup-right: calc(100% - var(--popup-anchor-left) + var(--popup-anchor-spacing));
    --popup-arrowContainer-left: auto;
    --popup-arrowContainer-right:
            calc(var(--popup-right) + var(--popup-border-width) - var(--popup-arrowContainer-width));
    --popup-arrowContainer-arrow-left: calc(-1 * var(--popup-arrow-side) / 2);
    --popup-arrowContainer-arrow-right: auto;
    --popup-arrowContainer-arrow-rotation: 135deg;
  }

  &.popup-right {
    --popup-left: calc(var(--popup-anchor-right) + var(--popup-anchor-spacing));
    --popup-right: auto;
    --popup-arrowContainer-left:
            calc(var(--popup-left) + var(--popup-border-width) - var(--popup-arrowContainer-width));
    --popup-arrowContainer-right: auto;
    --popup-arrowContainer-arrow-left: auto;
    --popup-arrowContainer-arrow-right: calc(-1 * var(--popup-arrow-side) / 2);
    --popup-arrowContainer-arrow-rotation: -45deg;
  }

  @media print {
    display: none;
  }
}

.popup_popup { // This extra level of nesting is necessary for interpreting "100%" in var(calc(...)) properly
  pointer-events: auto;
  position: absolute;
  box-sizing: border-box;
  width: var(--popup-width);
  max-width: var(--popup-max-width);
  left: var(--popup-left);
  top: var(--popup-top);
  right: var(--popup-right);
  bottom: var(--popup-bottom);
  padding: var(--popup-padding);
  background-color: var(--popup-background-color);
  border-width: var(--popup-border-width);
  border-radius: var(--popup-border-radius);
  border-color: var(--popup-border-color);
  border-style: var(--popup-border-style);
  box-shadow: var(--block-shadow-blurred);
  font-size: 15px;
  color: var(--color-text);

  .popup-bottom.popup-tooltip &::before { // When a need arises, other layouts can be implemented
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    height: calc(var(--popup-anchor-spacing) + var(--popup-border-width) + 1px);
    // 1px is due to possible rounding issues
  }

  &::after {
    // So that the shadow is not chopped off when there is not enough content on the page
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + var(--popup-border-width));
    height: var(--popup-vertical-margin);
    pointer-events: none;
  }
}

.popup_measurer {
  position: absolute;
  left: calc(-1 * var(--popup-horizontal-margin));
  top: calc(-1 * var(--popup-vertical-margin));
  right: calc(-1 * var(--popup-horizontal-margin));
  bottom: calc(-1 * var(--popup-vertical-margin));
  pointer-events: none;
}

.popup_ariaCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px; // The highlighted area would look nicer if it had 100% width and height, but that makes VoiceOver on iOS
  height: 1px; // click in the middle of the popup, effectively targeting whatever element happens to be there
  pointer-events: none;
}

.popup_arrowContainer {
  position: absolute;
  overflow: hidden;
  width: var(--popup-arrowContainer-width);
  height: var(--popup-arrowContainer-height);
  left: var(--popup-arrowContainer-left);
  top: var(--popup-arrowContainer-top);
  right: var(--popup-arrowContainer-right);
  bottom: var(--popup-arrowContainer-bottom);

  .popup-popover & {
    pointer-events: none;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    box-sizing: border-box;
    background-color: var(--popup-background-color);
    border-width: var(--popup-border-width);
    border-top-left-radius: var(--popup-arrow-border-radius);
    border-color: var(--popup-border-color);
    border-style: var(--popup-border-style);
    width: var(--popup-arrow-side);
    height: var(--popup-arrow-side);
    left: var(--popup-arrowContainer-arrow-left);
    top: var(--popup-arrowContainer-arrow-top);
    right: var(--popup-arrowContainer-arrow-right);
    bottom: var(--popup-arrowContainer-arrow-bottom);
    transform: rotate(var(--popup-arrowContainer-arrow-rotation));
    pointer-events: auto;
  }
}

@keyframes popup-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}