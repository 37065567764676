.about {
  padding: 70px;
  max-width: 1300px;
  margin: auto;
  font-size: 16px;
}

.about_header {
  font-size: 32px;
  line-height: 44px;
  margin: 64px 0 20px 0;
  font-weight: 600;
}

.about_quota {
  --about-quota-horizontal-padding: 12px;
  --about-quota-vertical-padding: 8px;
  --about-quota-border-radius: 6px;

  display: block;
  padding: var(--about-quota-vertical-padding) var(--about-quota-horizontal-padding);
  margin: calc(-1 * var(--about-quota-vertical-padding)) calc(-1 * var(--about-quota-horizontal-padding));
  border-radius: var(--about-quota-border-radius);
  transition: background-color var(--duration-color-transition);

  &.about_quota-highlighted {
    background-color: rgba(0, 0, 255, 0.1);
  }
}

.about_parallel {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 45px;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.about_right {
  width: 100%;
  text-align: center;
  font-style: normal;

  &.about_right-verticallyCentered {
    align-self: center;
  }
}

.about_mail {
  font-size: 24px;
  white-space: nowrap;
  margin-left: -20px; // To make the whole thing be centered visually

  .about_mailIcon {
    height: 20px;
    top: 3px;
    margin-right: 10px;
    fill: currentColor;
    opacity: 0.5;
    vertical-align: baseline;
    position: relative;
  }
}

.about_wiktionaryContainer {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.about_wiktionary {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top center;
}

@media screen and (max-width: 1000px) {
  .about {
    padding: 32px;
  }

  .about_header {
    margin-top: 48px;
  }

  .about_parallel {
    grid-template-columns: 1fr;
  }

  .about_right {
    display: none;
  }
}

.about_authors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "left photo right";
  grid-gap: 1em 40px; // The first value will be a margin between the paragraphs on a small screen (and half of
  // a margin between the last paragraph and the photo)
  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.about_leftAuthor {
  grid-area: left;
}

.about_rightAuthor {
  grid-area: right;
}

.about_authorsPhoto {
  grid-area: photo;
  width: 100%;
  margin: auto; // To center the image on smaller screens (see below)
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 1100px) {
  .about_authors {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left  right"
                         ".     .    " // For extra margin (see above)
                         "photo photo";
  }

  .about_authorsPhoto {
    width: 75%;
  }
}

@media screen and (max-width: 600px) {
  .about_authors {
    grid-template-columns: 1fr;
    grid-template-areas: "left "
                         "right"
                         ".    " // For extra margin
                         "photo";
  }

  .about_authorsPhoto {
    width: 100%;
  }
}