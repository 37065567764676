.error_icon {
  height: 1em;
  vertical-align: baseline;
  position: relative;
  top: 0.125em;
  margin-right: 0.5ch;

  .warningIcon_background {
    fill: var(--color-page-background);
  }

  .warningIcon_exclamationMark, .warningIcon_edge {
    fill: var(--color-primary);
  }
}