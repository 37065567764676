@import "../../includes/mixins";

.scrollContainer {
  --scrollContainer-controls-margin: 28px; // Must exceed potential scrollbar width/height
  --scrollContainer-button-size: 32px;
  --scrollContainer-button-border-radius: 10px;
  --scrollContainer-zoomControls-width: calc(
      var(--scrollContainer-button-size) + 2 * var(--scrollContainer-controls-margin)
  );
  --scrollContainer-horizontal-padding: calc(
      var(--scrollContainer-button-size) + 1.5 * var(--scrollContainer-controls-margin)
  );
  --scrollContainer-vertical-padding: var(--scrollContainer-controls-margin);
  position: relative;
  width: 100%;
  height: 100%;

  @media print {
    min-width: 100%;
    min-height: 100%;
    width: max-content;
    height: max-content;
  }
}

.scrollContainer_scroller {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  touch-action: pan-x pan-y; // Preventing pinch-zooming on mobile browsers

  @media not all and (any-pointer: fine) {
    &.scrollContainer_scroller-pinchZoomed {
      overflow: hidden;
      // This is to fight an iOS bug that makes the scroll bars visually repositioned as if they were actual children
    }
  }

  @media print {
    min-width: 100%;
    min-height: 100%;
    width: max-content;
    height: max-content;
  }

  a {
    cursor: inherit; // Otherwise the 'point' cursor can show during dragging (the actual cursor will be set with JS)
  }
}

.scrollContainer_padding {
  margin: auto;
  width: max-content;
  height: max-content;
  flex-grow: 0;
  flex-shrink: 0;
  padding: var(--scrollContainer-vertical-padding) var(--scrollContainer-horizontal-padding);
}

.scrollContainer_content {
  width: max-content;
  height: max-content;
  overflow: hidden; // Otherwise with small scales Firefox assumes there is more content and draws a vertical scrollbar
}

.scrollContainer_transform {
  width: max-content;
  height: max-content;
  transform-origin: top left;
}

.scrollContainer_original {
  width: max-content;
  height: max-content;
}

.scrollContainer_zoomControls {
  position: absolute;
  right: 0;
  bottom: 0;
  width: var(--scrollContainer-zoomControls-width);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse; // This is to hide the controls when the height of the container is small
  flex-wrap: wrap;
  pointer-events: none;

  @media not all and (any-pointer: fine) {
    display: none;
  }

  @media print {
    display: none;
  }

  &::before {
    content: "";
    display: block;
    width: var(--scrollContainer-zoomControls-width);
    height: var(--scrollContainer-controls-margin);
  }
}

.scrollContainer_zoomControlsContent {
  width: var(--scrollContainer-zoomControls-width);
  height: calc(2 * var(--scrollContainer-button-size) + var(--scrollContainer-controls-margin));
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.scrollContainer_zoomButtonsWrapper {
  width: var(--scrollContainer-button-size);
  height: calc(2 * var(--scrollContainer-button-size));
  border-radius: var(--scrollContainer-button-border-radius);
  box-shadow: var(--block-shadow-blurred);
  background-color: var(--color-page-background);
}

.scrollContainer_zoomButton {
  font-size: 16px;
  color: var(--color-text);
  width: var(--scrollContainer-button-size);
  height: var(--scrollContainer-button-size);
  line-height: var(--scrollContainer-controls-margin);
  text-align: center;
  appearance: none;
  background: var(--color-page-background);
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  user-select: none;
  transition-property: color, background-color;
  transition-duration: var(--duration-color-transition);
  font-weight: bold;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    color: var(--color-text-secondary-lighter);
  }

  &:not(:disabled) {
    cursor: pointer;

    @include outline {
      box-shadow: var(--text-button-outline);
      color: var(--color-text-contrasted);
      background-color: var(--color-text-semicontrasted-background);
    }

    &:focus {
      z-index: 2; // It goes here instead of in focus-visible to accommodate Safari, which doesn't support focus-visible
    }

    @include ruleWithHover {
      &:hover {
        color: var(--color-text-contrasted);
        background-color: var(--color-text-semicontrasted-background);
      }
    }

    &:active {
      background-color: var(--color-text-contrasted-background);
    }
  }

  &.scrollContainer_zoomButton-in {
    border-radius: var(--scrollContainer-button-border-radius) var(--scrollContainer-button-border-radius) 0 0;
  }

  &.scrollContainer_zoomButton-out {
    border-radius: 0 0 var(--scrollContainer-button-border-radius) var(--scrollContainer-button-border-radius);
  }
}

.scrollContainer_zoomButtonIcon {
  fill: currentColor;
  width: 25%;
}