@import "../../includes/mixins";
@import "../../includes/values";

.select {
  --select-miniControl-width: var(--size-miniControl-width);
  --select-miniControl-outline-width: var(--size-miniControl-outline-width);

  --select-size-smaller: 14px;
  --select-size-normal: 16px;
  --select-size-larger: 18px;

  --select-horizontal-padding: calc(var(--select-font-size) - 6px);

  --select-height: calc(2 * var(--select-font-size));

  width: 100%;
  position: relative;

  white-space: normal; // Otherwise potential white-space: nowrap from a parent could break error messages

  transition: opacity var(--duration-opacity-transition);
}

.select-disabled {
  opacity: 0.5;
}

.select-vertical-fixed {
  height: var(--select-height);
}

.select-size-smaller {
  --select-font-size: var(--select-size-smaller);
  --select-font-size-secondary: var(--select-size-smaller);
}

.select-size-normal {
  --select-font-size: var(--select-size-normal);
  --select-font-size-secondary: var(--select-size-smaller);
}

.select-size-larger {
  --select-font-size: var(--select-size-larger);
  --select-font-size-secondary: var(--select-size-normal);
}

.select-focusedBorderSize-small {
  --select-border-width: 2px;
  --select-border-radius: 3px;
}

.select-focusedBorderSize-large {
  --select-border-width: 3px;
  --select-border-radius: 5px;
}

.select_container {
  background-color: var(--color-input-background);
  box-shadow: 0 0 0 1px var(--color-text);
  border-radius: var(--select-border-radius);

  .select-vertical-fixed & {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    &::after {
      content: "";
      display: block;
      position: absolute;
      pointer-events: none;
      top: 100%;
      left: 0;
      right: 0;
      height: calc(var(--select-border-width) + 12px);
      // So that the drop-down list still looks nice when there is not enough content on the page
    }
  }

  .select-focusedInput & {
    box-shadow: 0 0 0 var(--select-border-width) var(--color-active);
  }
}

.select_inputArea {
  display: flex;
}

.select_input {
  width: 100%;
  min-width: 0; // Otherwise Firefox implies a min-width of ~190px (still showing it as auto)
  box-sizing: border-box;
  outline: none;
  border: none;
  background: none;
  padding: 0 0 0 var(--select-horizontal-padding);
  color: var(--color-text-contrasted);
  height: var(--select-height);
  font-size: var(--select-font-size);

  .select-invalid & {
    color: var(--color-text-secondary);
  }

  &::placeholder {
    color: var(--color-text-secondary);
    opacity: 1;
  }
}

.select_restore {
  --select-restore-icon-width: 15px;
  --select-restore-icon-margin: 5px;

  --select-restore-color: var(--color-text);
  background: none;
  color: var(--select-restore-color);
  transition-property: color, background-color;
  transition-duration: var(--duration-color-transition);
  display: flex;
  align-items: center;
  padding: 0 var(--select-horizontal-padding);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: 0 var(--select-border-radius) var(--select-border-radius) 0;
  position: relative;
  z-index: 0;
  font-size: var(--select-font-size-secondary);
  max-width: 40%;
  --dashedText-background: var(--color-input-background);
  outline: none;

  &:hover, &:focus, &:active {
    --select-restore-color: var(--color-text-contrasted);
    background-color: var(--color-text-contrasted-background);
    --dashedText-background: var(--color-text-contrasted-background);
  }

  // :focus is used instead of :focus-visible for better visual indication of when the button is being clicked
  // :active is needed because Firefox doesn't set :focus when a button is being clicked

  &:focus, &:active {
    outline: none;
    box-shadow: 0 0 0 var(--select-border-width) var(--color-active);
  }
}

.select_restoreIcon {
  width: var(--select-restore-icon-width);
  fill: var(--select-restore-color);
  transition: fill var(--duration-color-transition);
}

.select_restoreText {
  margin-left: var(--select-restore-icon-margin);
  max-width: calc(100% - (var(--select-restore-icon-width) + var(--select-restore-icon-margin)));
}

.select_cancel {
  display: flex;
  align-items: center;
  --select-cancel-extra-tapping-size: 0px;
  // We need px in "0px" because Chrome is not able to combine values of different units in the calc expression below
  margin: calc(-1 * var(--select-cancel-extra-tapping-size));
  padding: var(--select-cancel-extra-tapping-size) calc(var(--select-cancel-extra-tapping-size) +
           var(--select-horizontal-padding)
           - (var(--select-miniControl-outline-width) - var(--select-miniControl-width)) / 2
  );
  position: relative;
  z-index: 1;
  cursor: pointer;
}

@media (pointer: coarse) {
  .select_cancel {
    --select-cancel-extra-tapping-size: calc(var(--select-horizontal-padding) / 2);
  }
}

.select_cancelButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--select-miniControl-outline-width);
  height: var(--select-miniControl-outline-width);
  border-radius: 50%;
  transition: background-color var(--duration-color-transition);
}

.select_cancelIcon {
  width: var(--select-miniControl-width);
  height: var(--select-miniControl-width);
  fill: var(--color-text);
  transition: fill var(--duration-color-transition);
}

@media (hover: hover) { // To avoid highlighting the icon when the user clicks on the language name on a mobile device
  .select_cancel:hover .select_cancelButton {
    background-color: var(--color-danger);
  }

  .select_cancel:hover .select_cancelIcon {
    fill: var(--color-input-background);
  }
}

.select_button {
  --select-button-background-color: var(--color-secondary-inactive);
  cursor: pointer;
  height: var(--select-height);
  line-height: var(--select-height);
  font-size: var(--select-font-size-secondary);
  color: var(--color-text);
  transition: color var(--duration-color-transition);
  background-color: var(--select-button-background-color);
  border-radius: 0 var(--select-border-radius) var(--select-border-radius) 0;
  padding: 0 var(--select-horizontal-padding);
  display: flex;
  align-items: center;
  max-width: 70%; // In practice, if a language's name length is even close to this value,
                  // there would be hardly any words in this language's known vocabulary

  .select-invalid & {
    --select-button-background-color: var(--color-danger-inactive);
  }

  @include ruleWithHover {
    &:hover {
      color: var(--color-text-contrasted);
    }
  }
}

.select_buttonText {
  --dashedText-background: var(--select-button-background-color);
  max-width: 100%;
}

.select_contentArea {
  padding: calc(var(--select-horizontal-padding) / 2);
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: var(--select-horizontal-padding);
    right: var(--select-horizontal-padding);
    top: 0;
    height: 1px;
    background-color: var(--color-text);

    .select-focusedInput & {
      background-color: var(--color-active);
    }
  }
}

.select_option {
  padding: calc(var(--select-horizontal-padding) / 2);
  line-height: 1.3;
  white-space: nowrap;
  position: relative;
  border-radius: 2px; // Looks better than var(--select-border-radius)
  overflow: hidden;
  cursor: pointer;
}

.select_option-active {
  background-color: var(--color-active);
}

.select_optionDecoration {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: stretch;
}

.select_optionFader {
  --select-optionFader-background-main: var(--color-input-background);
  --select-optionFader-background-transparent: var(--color-input-background-transparent);
  // We need this because of the Safari bug (https://css-tricks.com/thing-know-gradients-transparent-black/)

  width: var(--select-horizontal-padding);
  background: linear-gradient(90deg,
          var(--select-optionFader-background-transparent),
          var(--select-optionFader-background-main));

  .select_option-active & {
    --select-optionFader-background-main: var(--color-active);
    --select-optionFader-background-transparent: var(--color-active-transparent);
  }
}

.select_optionArrowContainer {
  display: none;
  align-items: center;
  padding: 0 calc(var(--select-horizontal-padding) / 2);

  .select_option-active & {
    display: flex;
    background-color: var(--color-active);
  }
}

.select_optionArrow {
  width: var(--select-miniControl-width);
  fill: var(--color-secondary-inactive);
}

.select_optionText-size-smaller {
  font-size: var(--select-size-smaller);
}

.select_optionText-size-normal {
  font-size: var(--select-size-normal);
}

.select_optionText-size-larger {
  font-size: var(--select-size-larger);
}

.select_optionText-style-normal {
  color: var(--color-text);

  .select_option-active & {
    color: var(--color-text-inverse);
  }
}

.select_optionText-style-caption {
  color: var(--color-text-secondary);
  font-style: italic;
  margin-top: 1px;
  display: block;

  .select_option-active & {
    color: var(--color-text-inverse);
  }
}

.select_optionText-style-illustration {
  color: var(--color-active);
  margin-left: 0.5em;
  font-family: 'Pacifico', cursive;

  .select_option-active & {
    color: var(--color-text-inverse);
  }
}

.select_optionsFader {
  background: linear-gradient(0deg,
          var(--color-input-background),
          var(--color-input-background-semi-transparent) 70%,
          var(--color-input-background-transparent)
  );
}

.select_emptyOption {
  visibility: hidden;
}

.select_messageContainer {
  display: grid;
  grid-template-areas: "main";
}

.select_messageExpander {
  grid-area: main;
}

.select_message {
  grid-area: main;
  font-size: var(--select-font-size-secondary);
  font-style: italic;
  color: var(--color-text);
  padding: calc(var(--select-horizontal-padding) / 2);
  display: flex;
  align-items: center;
}

.select_hintKey {
  display: inline-block;
  border-radius: 3px;
  font-weight: normal;
  padding: 3px 6px;
  margin: 0 2px;
  border: 1px solid;
  background-color: var(--color-input-background);
  color: var(--color-text-contrasted);
  line-height: 1;
}

@media screen and (max-width: $val-header-threshold-width) {
  .select-hideRestoreText .select_restoreText {
    display: none;
  }

  .select_buttonText-full {
    display: none;
  }
}

@media not screen and (max-width: $val-header-threshold-width) {
  .select_buttonText-short {
    display: none;
  }
}