@use 'sass:math';

$menu-height: 44px;
$menu-m-height: 26px;
$menu-font-size: 18px;
$menu-m-font-size: 15px;
$menu-font-weight: bold;
$menu-m-font-weight: normal;
$menu-gap: 5px;
$menu-m-gap: 3px;
$menu-primary-item-width-multiplier: 10.5;
$menu-about-width-multiplier: 4.5;
$menu-m-padding: 12px;
$menu-m-about-padding-left: 8px;
$menu-m-about-padding-right: 16px;
$menu-border-size: 1px;
$menu-skew: 30deg;
$menu-min-extra-space: 50px;

$menu-primary-item-width: $menu-primary-item-width-multiplier * $menu-font-size;
$menu-etymology-width: $menu-primary-item-width;
$menu-relation-width: $menu-primary-item-width;
$menu-cognates-width: $menu-primary-item-width;
$menu-about-width: $menu-about-width-multiplier * $menu-font-size;
$menu-skew-shift-multiplier: math.tan($menu-skew) / 2;