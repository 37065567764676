@import "../../includes/mixins";
@import "../../includes/values";

.subrequestOptions {
  --subrequestOptions-horizontal-padding: #{$val-header-horizontal-padding};
  --subrequestOptions-icon-margin: 5px;
  --subrequestOptions-option-vertical-padding: 3px;
  --subrequestOptions-option-horizontal-padding: 12px;
  --subrequestOptions-option-margin: 18px;
  --subrequestOptions-vertical-padding: 10px;
  --subrequestOptions-font-size: 16px;
  --subrequestOptions-line-height: 1.5;

  background-color: var(--color-primary-inactive);
  --dashedText-background: var(--color-primary-inactive);
  box-shadow: var(--block-shadow);
  padding: var(--subrequestOptions-vertical-padding) var(--subrequestOptions-horizontal-padding);

  display: grid;
  grid-template-columns: 1fr auto 1fr;

  font-size: var(--subrequestOptions-font-size);
  line-height: var(--subrequestOptions-line-height);
}

.subrequestOptions_left {
  display: flex;
}

.subrequestOptions_right {
  justify-self: end;
}

.subrequestOptions_buttonContainer-left {
  margin-right: var(--subrequestOptions-horizontal-padding);
}

.subrequestOptions_buttonContainer-right {
  margin-left: var(--subrequestOptions-horizontal-padding);
}

.subrequestOptions_button {
  --subrequestOptions-button-vertical-padding: var(--subrequestOptions-option-vertical-padding);
  --subrequestOptions-button-horizontal-padding: calc(var(--subrequestOptions-horizontal-padding) / 2);

  white-space: nowrap;
  font-size: var(--subrequestOptions-font-size);
  color: var(--color-text);
  background: none;
  padding: var(--subrequestOptions-button-vertical-padding) var(--subrequestOptions-button-horizontal-padding);
  margin: 0 calc(-1 * var(--subrequestOptions-button-horizontal-padding));
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-radius: var(--size-text-button-border-radius);
  display: block;

  transition: color var(--duration-color-transition);

  height: calc(2 * var(--subrequestOptions-button-vertical-padding) +
               var(--subrequestOptions-line-height) * var(--subrequestOptions-font-size));

  @include outline {
    box-shadow: var(--text-button-outline);
  }

  @include duplicateWithHover('&:not(:disabled):hover', '&:not(:disabled):focus-visible') {
    color: var(--color-text-contrasted);
  }

  &:disabled {
    cursor: default;
  }
}

.subrequestOptions_buttonLabels {
  display: inline-grid;
  grid-template-areas: "main";
  justify-items: right;
}

.subrequestOptions_buttonLabel {
  grid-area: main;
  max-width: calc(max(100vw, var(--size-min-doc-width)) * 0.2);

  &.subrequestOptions_buttonLabel-hidden {
    visibility: hidden;
  }

  .subrequestOptions_buttonContainer-word & {
    font-weight: bold;
  }

  .subrequestOptions_button:disabled & {
    --dashedText-border-color: transparent;
  }

  @media print {
    --dashedText-border-color: transparent;
  }
}

.subrequestOptions_editIcon {
  display: inline-block;
  vertical-align: top;
  height: var(--subrequestOptions-font-size);
  position: relative;
  top: 5px;
  fill: currentColor;
  transition-property: opacity, transform;
  transition-duration: var(--duration-color-transition), var(--duration-transform-transition);
  // --duration-color-transition is used instead of --duration-opacity-transition to match the underline's transition
  margin-left: var(--subrequestOptions-icon-margin);

  .subrequestOptions_button:disabled & {
    opacity: 0.25;
  }

  .subrequestOptions_buttonContainer-topCollapsed & {
    transform: scaleY(-1);
    transform-origin: 50% 55%;
  }
}

.subrequestOptions_options {
  --horizontalScroller-background: var(--color-primary-inactive);
  --horizontalScroller-background-transparent: var(--color-primary-inactive-transparent);
  --horizontalScroller-option-vertical-padding: var(--subrequestOptions-option-vertical-padding);
  --horizontalScroller-option-horizontal-padding: var(--subrequestOptions-option-horizontal-padding);
  --horizontalScroller-option-horizontal-margin: var(--subrequestOptions-option-margin);
}

.subrequestOptions_wordSeparator {
  width: var(--subrequestOptions-horizontal-padding);
  margin-left: calc(-1 * var(--subrequestOptions-horizontal-padding));
  text-align: center;
  position: relative;
  top: 3px;
  font-weight: bold;
  pointer-events: none;
  color: var(--color-text);
  display: none;
}

@media print {
  .subrequestOptions_wordSeparator {
    display: block;
  }

  .subrequestOptions_buttonContainer-topSwitch, .subrequestOptions_editIcon {
    display: none;
  }
}

@media screen and (max-width: $val-threshold-width) {
  .subrequestOptions_buttonContainer-word {
    display: none;
  }
}