@import "../../includes/mixins";

.languagesOfInterest {
  --languagesOfInterest-font-size: 14px;
  --languagesOfInterest-height: 20px;

  --languagesOfInterest-control-border-radius: 2px;
  --languagesOfInterest-control-border-width: 2px;

  --languagesOfInterest-help-padding: 7px;
  --languagesOfInterest-control-padding: 4px;
  --languagesOfInterest-replaceableLanguage-padding: 2px;

  --languagesOfInterest-box-shadow: 0 0 0 var(--languagesOfInterest-control-border-width) var(--color-active);

  --dashedText-background: var(--color-primary);
  --dashedText-height: var(--languagesOfInterest-height);
  --dashedText-shift: 3px;

  font-size: var(--languagesOfInterest-font-size);
  height: var(--languagesOfInterest-height);
  line-height: var(--languagesOfInterest-height);
  color: var(--color-text-inverse);
  transition: opacity var(--duration-opacity-transition);

  display: grid;
  align-items: center;
  grid-template-areas: "icon text control";
  grid-template-columns: max-content 1fr max-content;
  padding: 0 16px; // This way, it looks a bit better, and the tooltip's/popup's triangles are aligned with the controls
}

.languagesOfInterest-hidden {
  opacity: 0;
  visibility: hidden;
}

.languagesOfInterest_help {
  position: relative;
  z-index: 2;
  padding: 0 var(--languagesOfInterest-help-padding);
  margin: 0 calc(-1 * var(--languagesOfInterest-help-padding));
  background: none;
  border: none;
  border-radius: var(--languagesOfInterest-control-border-radius);
  line-height: inherit;
  color: inherit;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  @include outline(-2px) {
    box-shadow: var(--languagesOfInterest-box-shadow);
  }
}

.languagesOfInterest_helpIcon {
  width: 14px;
  vertical-align: middle;

  & .helpIcon_edge {
    fill: var(--color-text-inverse);
  }

  & .helpIcon_questionMark {
    fill: var(--color-text-inverse);
    transition: fill var(--duration-color-transition);

    .languagesOfInterest_help-active & {
      fill: var(--color-primary);
    }
  }

  & .helpIcon_background {
    fill: var(--color-primary);
    transition: fill var(--duration-color-transition);

    .languagesOfInterest_help-active & {
      fill: var(--color-text-inverse);
    }
  }
}

.languagesOfInterest_text {
  position: relative;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  line-height: calc(var(--languagesOfInterest-height) + 2 * (var(--languagesOfInterest-control-border-width)));
  padding: 0 calc(var(--languagesOfInterest-control-border-width)
                  + var(--languagesOfInterest-text-extra-padding)
                  + var(--languagesOfInterest-control-padding))
           0 var(--languagesOfInterest-help-padding);

  --languagesOfInterest-text-extra-padding: 0px;

  .languagesOfInterest-singleLanguage & {
    --languagesOfInterest-text-extra-padding: var(--languagesOfInterest-replaceableLanguage-padding);
  }

  .languagesOfInterest:not(.languagesOfInterest-singleLanguage) & {
    // Otherwise Firefox just displays ... instead of the language name
    text-overflow: ellipsis;
  }
}

.languagesOfInterest_list {
  line-height: var(--languagesOfInterest-height);
}

.languagesOfInterest_replaceableLanguage {
  height: var(--languagesOfInterest-height);
  transition: color var(--duration-color-transition);
  background: none;
  border: none;
  border-radius: var(--languagesOfInterest-control-border-radius);
  line-height: inherit;
  padding: 0 var(--languagesOfInterest-replaceableLanguage-padding);
  margin: 0 calc(-1 * var(--languagesOfInterest-replaceableLanguage-padding));
  color: inherit;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  @include outline(-3px) {
    box-shadow: var(--languagesOfInterest-box-shadow);
  }

  @include duplicateWithHover('&:hover', '&:focus-visible') {
    color: var(--color-secondary-inactive);
  }
}

.languagesOfInterest_control {
  position: relative;
  z-index: 2;
  transition: color var(--duration-color-transition);
  background: none;
  border: none;
  border-radius: var(--languagesOfInterest-control-border-radius);
  line-height: inherit;
  padding: 0 var(--languagesOfInterest-control-padding);
  margin: 0 calc(-1 * var(--languagesOfInterest-control-padding));
  color: inherit;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  height: 100%;

  @include outline(-2px) {
    box-shadow: var(--languagesOfInterest-box-shadow);
  }

  @include duplicateWithHover('&:hover', '&:focus-visible') {
    color: var(--color-secondary-inactive);
  }

  &::before {    // This will come into play when the browser cannot apply text-overflow: ellipsis (in particular,
    content: ""; // when the single language displayed has a long name)
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: var(--languagesOfInterest-control-padding);
    background: linear-gradient(90deg, var(--color-primary-transparent), var(--color-primary) 50%, var(--color-primary));
  }
}

.languagesOfInterest_controlIconContainer {
  --languagesOfInterest-controlIconContainer-size: 12;
  --languagesOfInterest-controlIcon-size: 16;
  --languagesOfInterest-controlIcon-margin: calc(
      (var(--languagesOfInterest-controlIconContainer-size) - var(--languagesOfInterest-controlIcon-size)) / 2
  );
  --languagesOfInterest-controlIcon-scale: calc(
      var(--languagesOfInterest-controlIconContainer-size) / var(--languagesOfInterest-controlIcon-size)
  );

  width: calc(1px * var(--languagesOfInterest-controlIconContainer-size));
  height: calc(1px * var(--languagesOfInterest-controlIconContainer-size));
  position: relative;
}

.languagesOfInterest_controlIcon {
  position: absolute;
  width: calc(1px * var(--languagesOfInterest-controlIcon-size));
  height: calc(1px * var(--languagesOfInterest-controlIcon-size));
  left: calc(1px * var(--languagesOfInterest-controlIcon-margin));
  top: calc(1px * var(--languagesOfInterest-controlIcon-margin));
  fill: currentColor;

  // transform is applied to a shape instead of the SVG element itself due to jittering animation in Safari
  .addIcon_content, .settingsIcon_content {
    transition: transform var(--duration-color-transition);
    transform-origin: 50% 50%;
    transform: scale(var(--languagesOfInterest-controlIcon-scale));

    @include duplicateWithHover(
      '.languagesOfInterest_control:hover &',
      '.languagesOfInterest_control-active &',
      '.languagesOfInterest_control:focus-visible &'
    ) {
      transform: scale(1);
    }
  }
}

.languagesOfInterest_popupExplanation {
  margin-bottom: 10px;
}

.languagesOfInterest_popupAdd {
  position: relative;
  z-index: 2;
}

.languagesOfInterest_popupList {
  position: relative;
  z-index: 1;
  margin-top: 18px;
}

.languagesOfInterest_popupList-hidden {
  visibility: hidden; // So that all animations have a chance to complete
  position: absolute;
}