@import "../../includes/mixins";

.wordBox {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  // We need to indicate all of the relevant properties explicitly because in order to be measured the box is
  // pre-rendered in a different container than it will live in later on
  line-height: 1.3;
  font-family: var(--font-family-default);
  font-size: var(--font-size-default);
  font-style: normal;
  font-weight: normal;
  text-align: center;
}

.wordBox_language, .wordBox_sense {
  font-family: 'IBM Plex Sans', sans-serif;
}

.wordBox_language {
  color: var(--color-text-secondary-lighter);
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.04em;
  font-weight: bold;
}

.wordBox_wordContainer {
  color: var(--color-text-contrasted);
  font-size: 22px;
  display: inline-block;
  position: relative;

  // The following is needed so that text in links can be selected and copied to the clipboard.
  // Spans are used instead of ::before/after because Firefox doesn't allow text selection starting at pseudo-elements.
  .wordBox_wordContainerBefore, .wordBox_wordContainerAfter {
    display: inline-block;
    position: absolute;
    width: 0.5ch;
    top: 0;
    height: 100%;
  }

  .wordBox_wordContainerBefore {
    right: 100%;
  }

  .wordBox_wordContainerAfter {
    left: 100%;
  }

  .wordBox_ariaRanges {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1px; // To avoid misguiding highlighting (still, 0px text is not being read by VoiceOver on iOS)
    z-index: -1;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    opacity: 0;
    pointer-events: none;
    user-select: none; // Does not prevent from copying the text in Safari, but nothing seems to
  }
}

.wordBox_word {
  color: var(--color-text-contrasted);
  position: relative;
  display: inline-block;
  padding-top: 1px;
  padding-bottom: 2px;
  // The bottom padding is to equalize spacing to the following sense (if the sense is provided) as well as to
  // make sure that any subscript diacritics are fully within the box (if the sense is not provided). The padding
  // is applied here instead to .wordBox_wordContainer so that the link's clickable area is expanded.

  &.wordBox_word-link {
    text-decoration: none;
    --a-outline-offset: 0;
  }
}

.wordBox_wordForeground {
  position: relative;
  display: inline-block;
}

.wordBox_wordMain {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color var(--duration-color-transition);

  @include ruleWithHover {
    .wordBox_word-link:hover & {
      text-decoration-color: currentColor;
    }
  }
}

.wordBox_wordBackground, .wordBox_fragmentContainerWrapper {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.wordBox_fragmentContainerWrapper {
  visibility: hidden;
  overflow: hidden;
}

.wordBox_fragmentContainer {
  position: absolute;
  left: 0;
  top: 0;
}

.wordBox_highlighted {
  --wordBox-highlighted-horizontal-padding: 2px;
  --wordBox-highlighted-vertical-shift: 1px;
  --wordBox-highlighted-border-radius: 4px;
  display: block;
  position: absolute;

  left: calc(var(--wordBox-highlighted-left) - var(--wordBox-highlighted-horizontal-padding));
  width: calc(var(--wordBox-highlighted-width) + 2 * var(--wordBox-highlighted-horizontal-padding));
  top: var(--wordBox-highlighted-vertical-shift);
  height: 100%;

  border-radius: var(--wordBox-highlighted-border-radius);
  background-color: var(--color-primary-light-background);
}

.wordBox_sense {
  color: var(--color-primary);
  font-size: 14px;
  font-style: italic;
}

.wordBox_senseExpanderButton {
  --wordBox-senseExpanderButton-margin: 0.5ch;
  cursor: pointer;
  border: none;
  background: none;
  margin: 0 calc(-1 * var(--wordBox-senseExpanderButton-margin)) 0 0;
  padding: 0 var(--wordBox-senseExpanderButton-margin);
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  line-height: inherit;

  @include outline {
    outline: var(--size-text-button-outline) solid var(--color-active);
    -moz-outline-radius: var(--size-text-button-border-radius);
  }
}

.wordBox_senseExpanderIconContainer {
  --wordBox-senseExpanderIconContainer-margin: calc(-1 / 2 * var(--wordBox-senseExpanderButton-margin));
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &::before {
    display: block;
    content: "";
    position: absolute;
    border-radius: 50%;
    transition: background-color var(--duration-color-transition);

    left: var(--wordBox-senseExpanderIconContainer-margin);
    top: var(--wordBox-senseExpanderIconContainer-margin);
    right: var(--wordBox-senseExpanderIconContainer-margin);
    bottom: var(--wordBox-senseExpanderIconContainer-margin);

    @include duplicateWithHover('.wordBox_senseExpanderButton:hover &', '.wordBox_senseExpanderButton-active &') {
      background-color: var(--color-primary-light-background);
    }
  }
}

.wordBox_senseExpanderIcon {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  fill: var(--color-primary);
}

.wordBox_reconstructed {
  cursor: help;
}