.cognates {
  position: relative;
}

.cognates_words {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.cognates_word {
  position: absolute;
  left: 0;
  top: 0;
  width: max-content;
  height: max-content;
  z-index: 0;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: calc(-1 * var(--cognates-word-horizontal-padding));
    top: calc(-1 * var(--cognates-word-vertical-padding));
    right: calc(-1 * var(--cognates-word-horizontal-padding));
    bottom: calc(-1 * var(--cognates-word-vertical-padding));
    background: radial-gradient(
        var(--color-page-background),
        var(--color-page-background) 60%,
        var(--color-page-background-transparent)
    );
  }
}

.cognates_backgroundContainer {
  position: absolute;
  left: -50%;
  top: -50%;
  right: -50%;
  bottom: -50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
        var(--color-page-background-transparent),
        var(--color-page-background) 33%,
        var(--color-page-background)
    );
  }
}

.cognates_background {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  opacity: 0.1;

  .cognatesBackground_circles {
    stroke: var(--color-text);
  }
}